import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@environments/environment';
import {
  LicenseModel,
  updateSubCompanyCategoryModel,
} from '@shared/models/manager/license.model';

@Injectable({
  providedIn: 'root',
})
export class LincenseService {
  private urlManager = environment.endpointManager;
  public licenseListSubject = new BehaviorSubject<LicenseModel[]>([]);

  constructor(private http: HttpClient) {}

  public getLicenses(companyId: string): Observable<LicenseModel[]> {
    return this.http.get<LicenseModel[]>(
      `${this.urlManager}/Company/${companyId}/License`
    );
  }

  public updateCategorySubCompany(
    companyId: string,
    requestBody: updateSubCompanyCategoryModel
  ): Observable<updateSubCompanyCategoryModel> {
    return this.http.post<updateSubCompanyCategoryModel>(
      `${this.urlManager}/Company/${companyId}/License/subCompany`,
      requestBody
    );
  }

  // ################### #################### SUBJECT license #################### ###################

  setLicensesSubject(item: LicenseModel[]): void {
    this.licenseListSubject.next(item);
  }
  getLicensesSubject(): Observable<LicenseModel[]> {
    return this.licenseListSubject.asObservable();
  }
}
