import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  AddUserModel,
  HandleInLotModel,
  exportUserXBearer,
  PersonDataModel,
  updatePasswordModel,
  UpdatePersonalData,
  UserModel,
} from '../../models/user.model';
import { ParamValue } from '../../models/param-value.model';
import { QueryOptions } from '../../models/query-options.model';
import { Wrapper } from '../../models/pagination-wrapper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public personalData = new BehaviorSubject<any>(null);
  public personalDataImg = new BehaviorSubject<any>(null);
  public personalObject = new BehaviorSubject<any>(null);

  private baseUrlManager = environment.endpointManager;

  constructor(private http: HttpClient) {}

  public updateUser(userId: string, password: string): Observable<any> {
    const body = {
      password: password,
    };
    return this.http.post<updatePasswordModel>(
      `${this.baseUrlManager}/User/${userId}`,
      body
    );
  }

  public updateData(
    userId: string,
    personalData: UpdatePersonalData
  ): Observable<void> {
    return this.http
      .patch<void>(`${this.baseUrlManager}/User/${userId}`, personalData)
      .pipe(map((response) => response));
  }

  public getUserById(userId: string): Observable<PersonDataModel> {
    return this.http
      .get<PersonDataModel>(`${this.baseUrlManager}/User/${userId}`)
      .pipe(map((response) => new PersonDataModel(response)));
  }

  public getuser(param: Array<ParamValue>): Observable<Wrapper<UserModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<UserModel>>(`${this.baseUrlManager}/User`, httpOptions)
      .pipe(map((response) => new Wrapper<UserModel>(response)));
  }

  public addUser(
    companyId: string,
    profileId: string,
    user?: AddUserModel
  ): Observable<AddUserModel> {
    return this.http
      .post<AddUserModel>(
        `${this.baseUrlManager}/user/${companyId}/profile/${profileId}`,
        user
      )
      .pipe(map((response) => response));
  }

  public exportUserXBearer(requestBody?: exportUserXBearer): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrlManager}/user/DownloadUsers`, requestBody)
      .pipe(map((response) => response));
  }

  public getPublicKey(): Observable<string> {
    return this.http.get(`${this.baseUrlManager}/User/public-key`, {
      responseType: 'text',
    });
  }

  // ################### #################### SUBJECT #################### ###################

  public setNameUserSubject(namePersonalData: string): void {
    this.personalData.next(namePersonalData);
  }

  public getNameUserSubject(): Observable<string> {
    return this.personalData.asObservable();
  }

  public setImgUserSubject(namePersonalData: any): void {
    this.personalDataImg.next(namePersonalData);
  }

  public getImgUserSubject(): Observable<string> {
    return this.personalDataImg.asObservable();
  }

  public setUserSubject(userObject: PersonDataModel): void {
    this.personalObject.next(userObject);
  }
  public getUserSubject(): Observable<PersonDataModel> {
    return this.personalObject.asObservable();
  }

  public RegisterInLotBearer(
    companyId: string,
    files: File
  ): Observable<HandleInLotModel> {
    const formData = new FormData();

    formData.append('files', files, files.name);

    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<HandleInLotModel>(
      `${this.baseUrlManager}/User/company/${companyId}/cardHolder/upload`,
      formData,
      { headers: headers }
    );
  }
}
