<div mat-dialog-content>
  <div class="loading">
    <ng-lottie
      *ngIf="companyLoggedIn('biz')"
      [options]="options"
      [width]="inputWidth + 'px'"
      [height]="inputHeight + 'px'"
    ></ng-lottie>

    <mat-spinner color="$primary" *ngIf="!companyLoggedIn('biz')"></mat-spinner>
  </div>
</div>
