import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AnimationOptions } from 'ngx-lottie';

import { environment } from '../../../../environments/environment';
import { StorageService } from '@shared/services/shared-services/storage.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  inputWidth = 525;
  inputHeight = 425;

  options: AnimationOptions = {
    path: environment.production
      ? 'https://cdn-aiofiles-prd.biz.com.br/site/animations/loading_biz.json'
      : 'https://cdn-aiofiles.biz.com.br/site/animations/loading_biz.json',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService,
    public dialogRef: MatDialogRef<LoadingComponent>
  ) {}

  ngOnInit(): void {
    if (this.storageService.getItem('company') === 'biz') {
      this.dialogRef.addPanelClass('custom-dialog-loading-api');
    } else {
      this.dialogRef.addPanelClass('custom-dialog-loading-material');
    }

    if (this.data) {
      this.inputWidth = 252;
      this.inputHeight = 252;
    }
  }

  public companyLoggedIn(comapany: string): boolean {
    return this.storageService.getItem('company') === comapany;
  }
}
