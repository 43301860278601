export const roles = {
  permissionId: '',
  profileId: '',
  functionalityId: '',
  read: false,
  create: false,
  update: false,
  delete: false,
  report: false,
};
