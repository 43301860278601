import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../services/shared-services/session.service';
import { SessionClosedModalComponent } from './components/session-closed-modal/session-closed-modal.component';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpError: any) => {
        if (httpError instanceof HttpErrorResponse) {
          this.handleUnauthorizedAccess(httpError);
        }
        return throwError(httpError);
      })
    );
  }

  private handleUnauthorizedAccess(httpErrorResponse: HttpErrorResponse): void {
    if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
      if (httpErrorResponse.error) {
        this.handleConcurrentAccess();
      } else {
        this.sessionService.logoff();
      }
    }
  }

  private handleConcurrentAccess(): void {
    this.dialog
      .open(SessionClosedModalComponent, {
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        this.sessionService.logoff();
      });
  }
}
