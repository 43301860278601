import { Model } from '@shared/models/model';

export class Wrapper<T> extends Model {
  content!: Array<T>;
  firstPage!: boolean;
  lastPage!: boolean;
  hasContent!: boolean;
  hasNextPage!: boolean;
  hasPreviousPage!: boolean;
  page!: number;
  elements!: number;
  nextPage!: number;
  previousPage!: number;
  totalElements!: number;
  totalPages!: number;

  constructor(objeto?: any) {
    super(objeto);
  }
}
