import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private primary: string;
  private secondary: string;
  private tertiary: string;

  constructor(private storageService: StorageService) {
    this.primary = this.storageService.getItem('theme-color') || '#d43f11';
    this.secondary =
      this.storageService.getItem('secondary-color') || '#d43f11';
    this.tertiary = this.storageService.getItem('tertiary-color') || '#d43f11';
    this.applyTheme(this.primary, this.secondary, this.tertiary);
  }

  public changeTheme(
    primaryColor?: string,
    secondaryColor?: string,
    tertiaryColor?: string
  ): void {
    if (primaryColor) {
      this.storageService.setItem('theme-color', primaryColor);
      this.primary = primaryColor;
    }
    if (secondaryColor) {
      this.storageService.setItem('secondary-color', secondaryColor);
      this.secondary = secondaryColor;
    }
    if (tertiaryColor) {
      this.storageService.setItem('tertiary-color', tertiaryColor);
      this.tertiary = tertiaryColor;
    }

    this.applyTheme(this.primary, this.secondary, this.tertiary);
  }

  public setCustomTextColor(isCustomTextColor: boolean): void {
    const textColor = isCustomTextColor ? '#000000' : '#ffffff';

    document.documentElement.style.setProperty('--textColor', textColor);

    if (isCustomTextColor) {
      document.documentElement.style.setProperty(
        '--temporaryBackground',
        '#ffffff'
      );
    }
  }

  private applyTheme(color: string, secondary: string, tertiary: string): void {
    const isWhite = color.toLowerCase() === '#ffffff';

    if (isWhite) {
      color = '#d43f11';
    }

    document.documentElement.style.setProperty('--primary', color);
    document.documentElement.style.setProperty('--secondary', secondary);
    document.documentElement.style.setProperty('--tertiary', tertiary);

    //SET COLORS WITH OPACITY
    document.documentElement.style.setProperty(
      '--primaryOpacity',
      `${color}1A`
    );
  }
}
