import { Injectable } from '@angular/core';

import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { DefaultConstants } from '../../constants/default.constants';
import { environment } from '../../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class StorageService {
  private openMenuMobile = new BehaviorSubject<boolean>(false);
  private storageSub = new Subject<string>();
  private isProduction = environment.production;

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any): void {
    sessionStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  getItem(key: string): any {
    return sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
    this.storageSub.next('changed');
  }

  removeAll(): void {
    if (this.isProduction) {
      if (window.location.href.includes('elo')) {
        sessionStorage.clear();
        window.location.href = DefaultConstants.azureB2CAuthUrlBancobbPROD;
        return;
      }

      if (window.location.href.includes('biz')) {
        sessionStorage.clear();
        window.location.href = DefaultConstants.azureB2CAuthUrlPROD;
        return;
      }

      if (window.location.href.includes('raio')) {
        sessionStorage.clear();
        window.location.href = DefaultConstants.azureB2CAuthUrlPRODRaio;
        return;
      }
    } else {
      if (window.location.href.includes('elo')) {
        sessionStorage.clear();
        window.location.href = DefaultConstants.azureB2CAuthUrlBancobbHML;
        return;
      }
      if (window.location.href.includes('biz')) {
        sessionStorage.clear();
        window.location.href = DefaultConstants.azureB2CAuthUrlHML;
        return;
      }

      if (window.location.href.includes('raio')) {
        sessionStorage.clear();
        window.location.href = DefaultConstants.azureB2CAuthUrlHMLRaio;
        return;
      }
    }
    this.storageSub.next('changed');
  }

  getjwt(jwt: string): any {
    return JSON.parse(atob(jwt.split('.')[1]));
  }

  public setOpenMenuMobileSubject(isOpen: boolean): void {
    this.openMenuMobile.next(isOpen);
  }
  public getOpenMenuMobileSubject(): Observable<boolean> {
    return this.openMenuMobile.asObservable();
  }
}
