export class DefaultConstants {
  static azureB2CAuthUrlHML =
    'https://AllinOneBizBr.b2clogin.com/AllinOneBizBr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signin_signup&client_id=7f6cd5f7-b6a1-49df-b7e5-855a25854fbb&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fallinone-hml.biz.com.br%2Fallinone&scope=openid&response_type=id_token&prompt=login';

  static azureB2CAuthUrlPROD =
    'https://allinonebizprd.b2clogin.com/allinonebizprd.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signin_signup&client_id=95bad8fc-79af-4bde-95c1-93c69c388434&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fallinone.biz.com.br%2Fallinone&scope=openid&response_type=id_token&prompt=login';

  // adress ofs raio
  static azureB2CAuthUrlHMLRaio =
    'https://AllinOneBizBr.b2clogin.com/AllinOneBizBr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_raio_signin_signup&client_id=7f6cd5f7-b6a1-49df-b7e5-855a25854fbb&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportal-parceiro-hlg.raiobeneficios.com%2Fallinone&scope=openid&response_type=id_token&prompt=login';
  static azureB2CAuthUrlPRODRaio =
    'https://allinonebizprd.b2clogin.com/allinonebizprd.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_raio_signin_signup&client_id=95bad8fc-79af-4bde-95c1-93c69c388434&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportal-parceiro.raiobeneficios.com%2Fallinone&scope=openid&response_type=id_token&prompt=login';

  // adress ofs bb beneficios

  //change url for b2c elo of prod
  static azureB2CAuthUrlBancobbPROD =
    'https://allinoneelo.b2clogin.com/allinoneelo.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_elo_signin_signup&client_id=d92fe6f2-2f21-484a-9ebd-06ee485ad9d5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportalbeneficios.elo.com.br%2Fallinone&scope=openid&response_type=id_token&prompt=login';

  static azureB2CAuthUrlBancobbHML =
    'https://allinoneelohml.b2clogin.com/allinoneelohml.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_elo_signin_signup&client_id=9f3e3b67-3245-4bed-955f-0d158e70270e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportalbeneficios-hml.elo.com.br%2Fallinone&scope=openid&response_type=id_token&prompt=login';

  static validDDDs = [
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '21',
    '22',
    '24',
    '27',
    '28',
    '31',
    '32',
    '33',
    '34',
    '35',
    '37',
    '38',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '51',
    '53',
    '54',
    '55',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '71',
    '73',
    '74',
    '75',
    '77',
    '79',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ];
}
