import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

@Injectable({ providedIn: 'root' })
export class CustomDateAdapter extends MomentDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
  }
}
