import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectIconDirective } from './select-icon.directive';

@NgModule({
  imports: [CommonModule],
  exports: [SelectIconDirective],
  declarations: [SelectIconDirective],
  providers: [],
})
export class SelectIconModule {}
