import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { QueryOptions } from '../../models/query-options.model';
import { ParamValue } from '../../models/param-value.model';
import { Wrapper } from '@shared/models/pagination-wrapper';
import { AccessAllIWrapper } from '@shared/models/manager.model';
import {
  CompanyAccessModel,
  CompanyAddressModel,
  CompanyListModel,
  CompanyModel,
  SubCompanysModal,
  updateMyBrandModel,
} from '../../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private urlCompany = environment.endpointManager;
  public companySubject = new BehaviorSubject<any>(null);
  public CompanyResponseSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  // ################### #################### Company #################### ###################

  public getCompany(companyId: string): Observable<CompanyModel> {
    return this.http
      .get<CompanyModel>(`${this.urlCompany}/Company/${companyId}`)
      .pipe(map((response) => new CompanyModel(response)));
  }

  public getCompanyList(
    param?: Array<ParamValue>
  ): Observable<Array<CompanyListModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http.get<Array<CompanyListModel>>(
      `${this.urlCompany}/Company`,
      httpOptions
    );
  }

  public updateCompany(
    companyId: string,
    companyModel: updateMyBrandModel
  ): Observable<any> {
    return this.http
      .patch<updateMyBrandModel>(
        `${this.urlCompany}/Company/${companyId}`,
        companyModel
      )
      .pipe(map((response) => response));
  }

  public getMobileAccess(companyId: string): Observable<CompanyAccessModel> {
    return this.http.get<CompanyAccessModel>(
      `${this.urlCompany}/Company/${companyId}/mobileAccess`
    );
  }

  // ################### #################### CompanyAddress #################### ###################

  public getCompanyAddress(companyId: string): Observable<CompanyAddressModel> {
    return this.http
      .get<CompanyAddressModel>(
        `${this.urlCompany}/Company/${companyId}/Address`
      )
      .pipe(map((response) => new CompanyAddressModel(response)));
  }

  public updateCompanyAddress(
    companyId: string,
    companyModel: CompanyAddressModel
  ): Observable<any> {
    return this.http
      .put<any>(`${this.urlCompany}/Company/${companyId}/Address`, companyModel)
      .pipe(map((response) => response));
  }

  // ################### #################### SubCompanys #################### ###################

  public getSubCompanys(
    companyId: string,
    param: Array<ParamValue>
  ): Observable<Wrapper<SubCompanysModal>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<SubCompanysModal>>(
        `${this.urlCompany}/Company/${companyId}/subCompany`,
        httpOptions
      )
      .pipe(map((response) => new Wrapper<SubCompanysModal>(response)));
  }

  public postSubCompany(requestBody: any, companyId: string): Observable<any> {
    return this.http.post<any>(
      `${this.urlCompany}/Company/${companyId}/subCompany`,
      requestBody
    );
  }

  // ################### #################### subject #################### ###################

  setFantasyNameCompanySubject(item: string): void {
    this.companySubject.next(item);
  }
  getFantasyNameCompanySubject(): Observable<string> {
    return this.companySubject.asObservable();
  }

  // ################### #################### subject getCompany #################### ###################

  setCompanyResponseSubject(item: AccessAllIWrapper): void {
    this.CompanyResponseSubject.next(item);
  }
  getCompanyResponseSubject(): Observable<AccessAllIWrapper> {
    return this.CompanyResponseSubject.asObservable();
  }
}
