import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { StorageService } from '@shared/services/shared-services/storage.service';

@Injectable({ providedIn: 'root' })
export class HeaderInterceptor implements HttpInterceptor {
  private subscriptionKey = environment.subscriptionKey;

  constructor(private storageService: StorageService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request is a JSONP request
    if (req.method === 'JSONP') {
      // Simply forward JSONP requests without modifying headers
      return next.handle(req);
    }

    let headers = req.headers;

    if (
      req.url.includes(environment.endpointViaCep) ||
      req.url.includes(environment.endpontReceita)
    ) {
      headers = headers.set('Content-Type', 'application/json');
    } else {
      headers = headers
        .set('Ocp-Apim-Subscription-Key', this.subscriptionKey)
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set(
          'Authorization',
          `Bearer ${this.storageService.getItem('accessToken')}`
        )
        .set('X-Frame-Options', 'DENY')
        .set('Content-Security-Policy', "frame-ancestors 'self'");
    }

    return next.handle(req.clone({ headers }));
  }
}
