import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[selectIcon]',
})
export class SelectIconDirective implements OnInit {
  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.initCustomIcon();
  }
  public initCustomIcon() {
    const icon = this.elRef.nativeElement;

    if (icon) {
      this.addColorfulIcons(icon);
    }
  }

  public addColorfulIcons(icon: HTMLElement) {
    let iconStyleSheet;
    const cssRules = new Array();

    for (let index = 0; index < document.styleSheets.length; index++) {
      if (document.styleSheets[index].title === 'bif-alone') {
        iconStyleSheet = document.styleSheets[index];
      }
    }

    for (
      let index = 0;
      index < (iconStyleSheet?.cssRules.length ?? 0);
      index++
    ) {
      cssRules.push(iconStyleSheet?.cssRules[index]);
    }

    const className = icon.className;
    const c = className.match(/biz-[^\s'"]+/);
    const iconName = c && c[0].length > 4 ? c[0].substring(4) : undefined;
    const pathCount = cssRules.filter(({ cssText }) =>
      cssText.includes(iconName)
    ).length;

    if (iconName && pathCount) {
      this.addLayers(icon, pathCount);
    }
  }

  public addLayers(icon: HTMLElement, numberOfLayers: number) {
    for (let i = 1; i <= numberOfLayers; i++) {
      const childNode = document.createElement('span');
      childNode.classList.add(`path${i}`);
      icon.appendChild(childNode);
    }
  }
}
