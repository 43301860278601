import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { SelectIconModule } from '@shared/directives/select-icon.directive.module';

@Component({
  selector: 'app-session-closed-modal',
  templateUrl: './session-closed-modal.component.html',
  standalone: true,
  imports: [SelectIconModule, MatButtonModule, MatDialogModule],
})
export class SessionClosedModalComponent {}
