import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { LoadingComponent } from '../components/loading/loading.component';
import { SnackbarService } from '@shared/services/shared-services/snackbar.service';

export class GeneralHttpErrorMixin {
  constructor(
    public snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  private loadingDialog!: MatDialogRef<LoadingComponent>;
  private errorMessage!: string;

  public handleHttpError(
    customMessageError: string,
    httpError: HttpErrorResponse,
    reqMethod: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET'
  ): void {
    switch (reqMethod) {
      case 'POST':
        this.postRules(httpError);
        break;

      case 'PUT':
        this.getRules(httpError);
        break;

      case 'PATCH':
        this.postRules(httpError);
        break;

      case 'DELETE':
        this.getRules(httpError);
        break;

      case 'GET':
        this.getRules(httpError);
        break;

      default:
        break;
    }

    this.snackbarService.openSnackbar({
      message: this.errorMessage ? this.errorMessage : customMessageError,
      type: 'error',
    });
  }

  private postRules(httpError: HttpErrorResponse) {
    if (httpError?.error?.detail) {
      this.errorMessage = httpError.error.detail;
    } else {
      this.errorMessage = httpError?.error[0].message;
    }
  }

  private getRules(httpError: HttpErrorResponse) {
    if (httpError?.status !== 404) {
      if (httpError?.error?.detail) {
        this.errorMessage = httpError.error.detail;
      } else {
        this.errorMessage = httpError?.error[0].message;
      }
    }
  }

  public openDialogLoading(): void {
    this.loadingDialog = this.dialog.open(LoadingComponent, {
      disableClose: true,
      data: true,
      autoFocus: true,
    });
  }
}
